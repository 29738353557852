<!--
 * @Author: lisushuang
 * @Date: 2021-11-15 11:02:30
 * @LastEditors: lisushuang
 * @LastEditTime: 2021-11-16 00:07:55
 * @Description: 用iframe 引入页面编辑器
 * @FilePath: /dwh_front/src/views/objects/pageEditor.vue
-->

<template>
  <div style="width: 100%; height: 100%" v-loading="loading" element-loading-text="页面编辑器装载中">
    <iframe
      style="width: 100%; height: 100%"
      :src="'http://page-editor.bimcc.site/modify-page?id=' + id"
      v-if="showEditor"
      frameborder="0"
      @load="loading = false"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading:true,
      showEditor: true,
      id: this.$route.query.id,
      handler: (e) => {
        if (e.data && e.data.type == "closeEditor") {
          this.$router.back();
        }
      },
    };
  },
  methods: {},

  mounted() {
    window.addEventListener("message", this.handler, false);
  },
  destroyed() {
    window.removeEventListener("message", this.handler, false);
  },
};
</script>

<style scoped lang="less">
</style>

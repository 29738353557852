import { render, staticRenderFns } from "./pageEditor.vue?vue&type=template&id=1793b180&scoped=true&"
import script from "./pageEditor.vue?vue&type=script&lang=js&"
export * from "./pageEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1793b180",
  null
  
)

export default component.exports